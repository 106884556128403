<template>
  <!--srcviewscompanydetailsBusinessActivitiesSupplyChainvuehtmlStart-->

  <div id="defaultId1 " style="padding-bottom:20px">
    <div id="g2ede8" class="operations-content" >
      <h2 id="gf7b16" class="operations-head detail_title_20">Product Supply Chain Overview</h2>
      <div class="operations-box">
        <div ref="SupplyChainEchart" style="width: 100%; height: 642px"></div>
      </div>
    </div>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>
</template>

<script>
import { getProductSupplyChain,} from '@/api/companyApi.js';
import ReLogin from "../../../../components/ReLogin.vue";
export default {
  name: "",
  // props: {},
  components: {
    ReLogin,
  },
  data() {
    return {
      data: {
        links: [],
        nodes: [],
      },
      nodes: [], //{ name: 'Climate change' }
      links: [],
      reLoginVis: false,
      companyList: [],
    };
  },
  mounted() {
    this.getData();
    
    this.$emit("UpdatamarkTotalList", []);
  },
  methods: {
    default() {},
    getData() {
      getProductSupplyChain({
        hsCode: this.$route.query.id3a,
        type: 2
      }).then(result => {
          // let data = JSON.parse(crypto.decrypt(result));
          let data=result
          if (data .code==200) {
              const listNode = data.data.nodes;
              let arr = [];
              const listLinks = data.data.links;
              this.companyList = data.data.nodes;
              let arrLink = [];

              listNode.forEach(item => {
                  const pobj = { name: item.nameEn,
                   };
                  arr.push(pobj);
              });
              this.nodes = arr;
       
          
              listLinks.forEach((item, index) => {
                  const obj = {
                      source: item.sourceEn,
                      target: item.targetEn,
                      value: item.value,
                  };
                  arrLink.push(obj);
              });
        
              // this.linkpre = arrLink
              this.links = arrLink;

              // this.findLink()
              this.suppleEchart();
          }
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      });
    },
    // getData() {
    //   let data = this.data;
    //   const listNode = data.nodes;
    //   let arr = [];
    //   const listLinks = data.links;
    //   let arrLink = [];

    //   listNode.forEach((item) => {
    //     const pobj = { name: item.nameEn };
    //     arr.push(pobj);
    //   });
    //   this.nodes = arr;

    //   listLinks.forEach((item, index) => {
    //     const obj = {
    //       source: item.sourceEn,
    //       target: item.targetEn,
    //       value: item.value,
    //     };
    //     arrLink.push(obj);
    //   });
    //   // this.linkpre = arrLink
    //   this.links = arrLink;
    //   // this.findLink()
    //   this.suppleEchart();
    // },
    suppleEchart() {
      let that = this;
      this.echartsBox = this.$echarts.init(this.$refs.SupplyChainEchart);
      const option = {
        grid: {
          left: "0%", // 调整这个数值来控制图表在容器中的左侧位置
          // 其他 grid 相关设置
        },
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
        },
        series: [
          {
            type: "sankey",
            data: that.nodes,
            links: that.links,
            emphasis: {
              focus: "adjacency",
            },
            levels: [
              {
                depth: 0,
                itemStyle: {
                  color: "#fbb4ae",
                },
                lineStyle: {
                  color: "source",
                  opacity: 0.6,
                },
              },
              {
                depth: 1,
                itemStyle: {
                  color: "#b3cde3",
                },
                lineStyle: {
                  color: "source",
                  opacity: 0.6,
                },
              },
              {
                depth: 2,
                itemStyle: {
                  color: "#ccebc5",
                },
                lineStyle: {
                  color: "source",
                  opacity: 0.6,
                },
              },
              {
                depth: 3,
                itemStyle: {
                  color: "#decbe4",
                },
                lineStyle: {
                  color: "source",
                  opacity: 0.6,
                },
              },
            ],
            lineStyle: {
              curveness: 0.5,
            },
          },
        ],
      };
      this.echartsBox.setOption(option);

    },
  },
  computed: {},

};
</script>
<style scoped>
.operations-content {
  padding: 30px 40px;
  height: 100%;
}
.operations-head {
  font-size: 30px;
  font-family: 'DMSans Bold';
  /* font-family: "Arial Bold"; */
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
}
.operations-content {
  padding: 30px 40px;
      background: #FFFFFF;
box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.12);
border-radius: 4px;
}
/deep/ .el-table td.el-table__cell {
    border-right: none;
    height: 3.71rem;
}
/*cssStart*/

/*cssEnd*/
</style>